import {
  Resolved,
  isExpression,
  Expression,
  NamedExpression,
} from "@trackback/widgets";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { NamedResolverFunction } from "./types";
import { LoadingStateCounter } from "./utils/loading-state-counter.class";

export interface DataChangeCallback<O> {
  next: (next: O) => Function<[], void> | void;
  error: (error: any) => void;
}

const DEFAULT_OPTIONS: ParseOptions = { context: {} } as const;

export type ResolverFunction<I extends any[], O> = (
  parser: Parser,
  options: ParseOptions,
  onData: DataChangeCallback<O>,
  args: I
) => Function<[], void> | void;

export type Resolver<I extends any[], O> = ResolverFunction<I, O> & {
  id: string;
};

export interface ParseOptions<
  C extends Record<string, unknown> = Record<string, unknown>
> {
  /**
   * Arbitrary immutable data.
   */
  readonly context?: C;

  /**
   * Only properties which are whitelisted will be parsed on objects.
   */
  readonly keyWhitelist?: string[];

  /**
   * Properties of objects which's key is blacklisted will not be parsed.
   */
  readonly keyBlacklist?: string[];

  /**
   * If an object or array is parsed, this is the maximum depth for recursive parsing.
   */
  readonly maxParseDepth?: number;

  /**
   * Helper that can be used by bindings declare themselves as loading or complete
   */
  readonly loadingStateCounter?: LoadingStateCounter;

  /**
   * An optional log function that is called whenever an expression got evaluated
   */
  readonly log?: (...values: any[]) => void;
}

export class Parser {
  private readonly _resolverMap: Record<string, ResolverFunction<any, any>> =
    {};
  private readonly _resolverLoaderMap: Record<
    string,
    () => Promise<ResolverFunction<any, any>>
  > = {};

  public getResolver<I extends any[], R>(id: string) {
    if (!(id in this._resolverMap || id in this._resolverLoaderMap)) {
      throw new Error(`Unkown resolver: ${id}`);
    }
    if (!(id in this._resolverMap)) {
      return this._resolverLoaderMap[id]().then((resolverFn) => {
        this._resolverMap[id] = resolverFn;
        return resolverFn;
      });
    }
    return this._resolverMap[id];
  }

  public registerResolverLoader<
    T extends NamedExpression<string, any[], unknown>
  >(
    loaderFn: () => Promise<{ default: NamedResolverFunction<T> }>,
    id: T["$"],
  ) {
    this._resolverLoaderMap[id] = () => loaderFn().then((it) => it.default);
  }

  public registerResolver(resolver: Resolver<any, any>): void;
  public registerResolver<T extends NamedExpression<string, any[], unknown>>(
    name: T["$"],
    resolver: NamedResolverFunction<T>
  ): void;
  public registerResolver(
    p1: string | Resolver<any, any>,
    p2?: Resolver<any, any>
  ): void {
    let id: string;
    let resolver: Resolver<any, any>;
    if (typeof p1 === "function") {
      if (typeof p1.id === "string") {
        id = p1.id;
        resolver = p1;
      } else {
        throw new Error(
          "A resolver needs an id if no name is explicitly specified"
        );
      }
    } else if (typeof p2 === "function") {
      id = p1;
      resolver = p2;
    } else {
      throw new Error(`Invalid resolver registration arguments ${arguments}`);
    }
    if (id in this._resolverMap) {
      throw new Error(`Duplicate resolver registration for ${id}`);
    }
    this._resolverMap[id] = resolver;
  }

  public parseOnce<I>(
    expression: I,
    options?: ParseOptions
  ): Observable<Resolved<I>> {
    return this.parse(expression, options).pipe(first());
  }

  public parse<I>(
    input: I,
    options: ParseOptions = { context: {} }
  ): Observable<Resolved<I>> {
    return new Observable<Resolved<I>>((subscriber) =>
      this.parseWithCallback(
        input,
        {
          error: subscriber.error.bind(subscriber),
          next: subscriber.next.bind(subscriber),
        },
        options
      )
    );
  }

  public parseAsPromise<I>(
    input: I,
    options: ParseOptions = DEFAULT_OPTIONS
  ): Promise<Resolved<I>> {
    let done: any;
    return new Promise<Resolved<I>>((resolve, reject) => {
      done = this.parseWithCallback(
        input,
        {
          error: reject,
          next: resolve,
        },
        options
      );
    }).finally(() => (typeof done === "function" ? done() : {}));
  }

  public parseWithCallback<I>(
    input: I,
    onDataChange: DataChangeCallback<Resolved<I>>,
    options: ParseOptions = DEFAULT_OPTIONS,
    depth = 0,
    shouldParseExpressions = true
  ): void | Function<[], void> {
    try {
      const maxDepthReached =
        typeof options.maxParseDepth === "number" &&
        depth > options.maxParseDepth;

      if (Array.isArray(input) && !maxDepthReached) {
        return this.parseArray(
          input,
          onDataChange as any,
          options,
          depth,
          shouldParseExpressions
        );
      } else if (input !== null && typeof input === "object") {
        if (!shouldParseExpressions || !isExpression(input)) {
          if (!maxDepthReached) {
            return this.parseObject(input, onDataChange, options, depth);
          } else {
            return onDataChange.next(input as any);
          }
        } else {
          return this.parseExpression(input, onDataChange, options);
        }
      } else {
        return onDataChange.next(input as any);
      }
    } catch (error) {
      return onDataChange.error(error);
    }
  }

  public parseExpression = <I extends any[], O>(
    { $: id, args = [] as I }: Expression<I, O>,
    onDataChange: DataChangeCallback<O>,
    options: ParseOptions = DEFAULT_OPTIONS
  ) => {
    const resolverResult = this.getResolver(id);
    const resolverExecutor = (resolver: ResolverFunction<any, any>) => {
      let callback = onDataChange;
      // if (options.log) {
      //   callback = {
      //     error: (error) => {
      //       options.log && options.log(id, args, error);
      //       return onDataChange.error(error);
      //     },
      //     next: (next) => {
      //       options.log && options.log(id, args, next);
      //       return onDataChange.next(next);
      //     },
      //   };
      // }
      return resolver(this, options, callback, args);
    }

    if (resolverResult instanceof Promise) {
      let resolverCancel: void | Function<[], void>;
      let cancelled = false;

      resolverResult
        .then(resolver => {
          if (!cancelled) {
            resolverCancel = resolverExecutor(resolver);
          }
        }, onDataChange.error);
      return () => {
        cancelled = true;
        if (resolverCancel) {
          resolverCancel();
        }
      };
    } else {
      return resolverExecutor(resolverResult);
    }
  };

  public parseArray = <I extends ReadonlyArray<unknown>>(
    input: I,
    onDataChange: DataChangeCallback<Resolved<I>>,
    options: ParseOptions = DEFAULT_OPTIONS,
    depth = 0,
    shouldParseExpressions = true
  ) => {
    if (!input.length) {
      return onDataChange.next(input as any);
    }
    let isDone = false;
    const parsedInput = [] as any;
    let active = input.length;
    const hasEmitted: { [idx: number]: boolean } = {};
    const doneFns = input.map((item, index) =>
      this.parseWithCallback(
        item,
        {
          error: onDataChange.error,
          next: (parsedItem) => {
            if (isDone) {
              return;
            }
            parsedInput[index] = parsedItem;
            if (!(index in hasEmitted)) {
              active--;
              hasEmitted[index] = true;
            }
            if (active === 0) {
              return onDataChange.next(parsedInput.slice() as any);
            }
          },
        },
        options,
        depth + 1,
        shouldParseExpressions
      )
    );
    return () => {
      if (isDone) {
        throw new Error("Cannot execute unsubscribe if already unsubscribed");
      }
      isDone = true;
      doneFns.forEach((done) => done && done());
    };
  };

  public parseObject = <I extends Record<string, any>>(
    input: I,
    onDataChange: DataChangeCallback<Resolved<I>>,
    options: ParseOptions = DEFAULT_OPTIONS,
    depth = 0
  ) => {
    if (!Object.keys(input).length) {
      return onDataChange.next(input as Resolved<I>);
    }

    let isDone = false;
    const parsedInput: any = {} as Resolved<I>;
    const entries = Object.entries(input);

    const doneFns = entries.map(([key, property]) => {
      const shouldNotParseExpressions =
        (options.keyWhitelist && !options.keyWhitelist.includes(key)) ||
        (options.keyBlacklist && options.keyBlacklist.includes(key));
      if (shouldNotParseExpressions && isExpression(property)) {
        parsedInput[key] = property;
        if (Object.keys(parsedInput).length >= entries.length) {
          return onDataChange.next({ ...parsedInput });
        }
      } else {
        return this.parseWithCallback(
          property,
          {
            error: onDataChange.error,
            next: (parsedProperty) => {
              if (isDone) {
                return;
              }
              parsedInput[key] = parsedProperty;
              if (Object.keys(parsedInput).length >= entries.length) {
                return onDataChange.next({ ...parsedInput });
              }
            },
          },
          options,
          depth + 1,
          !shouldNotParseExpressions
        );
      }
    });
    return () => {
      if (isDone) {
        throw new Error("Cannot execute unsubscribe if already unsubscribed");
      }
      isDone = true;
      doneFns.forEach((done) => done && done());
    };
  };
}

export type Function<I extends any[], O> = (...args: I) => O;

export const createCallbackResolver = <I extends any[], O>(
  fn: Function<Resolved<I>, O>,
  id: string
): Resolver<I, O> => {
  const resolver: Resolver<I, O> = (parser, options, onDataChange, args) =>
    parser.parseArray(
      args,
      {
        error: onDataChange.error,
        next: (resolvedArgs) => onDataChange.next(fn(...resolvedArgs)),
      },
      options
    );
  resolver.id = id;
  return resolver;
};

export const createNoArgsObservableResolver = <O>(
  fn: Function<[], Observable<O>> | Observable<O>,
  id: string
): Resolver<[], O> => {
  const resolver: Resolver<[], O> = (_parser, _options, onDataChange) => {
    const subscription = (fn instanceof Observable ? fn : fn()).subscribe(
      onDataChange.next,
      onDataChange.error
    );
    return subscription.unsubscribe.bind(subscription);
  };
  resolver.id = id;
  return resolver;
};

export function appendContext(
  options: ParseOptions,
  merge: { [key: string]: any }
): ParseOptions {
  return {
    ...options,
    context: options.context ? { ...options.context, ...merge } : merge,
  };
}

export function combineLatestArray<T>(
  fns: Array<(onChange: DataChangeCallback<T>) => Function<[], void> | void>,
  onChange: DataChangeCallback<T[]>
): Function<[], void> | void {
  if (fns.length === 0) {
    return onChange.next([]);
  }
  let done = false;
  const result = new Array<T>(fns.length);
  let active = fns.length;
  const hasEmitted: { [idx: number]: boolean } = {};
  const doneFns = fns.map((fn, index) => {
    return fn({
      error: onChange.error,
      next: (subResult) => {
        if (done) {
          return;
        }
        result[index] = subResult;
        if (!(index in hasEmitted)) {
          active--;
          hasEmitted[index] = true;
        }
        if (active === 0) {
          return onChange.next(result.slice());
        }
      },
    });
  });
  return () => {
    if (done) {
      throw new Error("Cannot execute unsubscribe if already unsubscribed");
    }
    done = true;
    doneFns.forEach((doneFn) => doneFn && doneFn());
  };
}

export function combineLatestObject<T extends Record<string, any>>(
  fns: Array<{
    key: string;
    fn: (onChange: DataChangeCallback<any>) => Function<[], void> | void;
  }>,
  onChange: DataChangeCallback<T>
): Function<[], void> | void {
  if (fns.length === 0) {
    return onChange.next({} as T);
  }
  let done = false;
  const result: any = {};
  const doneFns = fns.map(({ key, fn }) =>
    fn({
      error: onChange.error,
      next: (subResult) => {
        if (done) {
          return;
        }
        result[key] = subResult;
        if (Object.keys(result).length >= fns.length) {
          return onChange.next({ ...result });
        }
      },
    })
  );
  return () => {
    if (done) {
      throw new Error("Cannot execute unsubscribe if already unsubscribed");
    }
    done = true;
    doneFns.forEach((doneFn) => doneFn && doneFn());
  };
}
